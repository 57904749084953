import React from "react"
//import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "./vision.module.scss"

function PageVision() {

  const PageVisionData = useStaticQuery(graphql`
    {
      allWordpressPage(filter: {wordpress_id: {eq: 11}}) {
        edges {
          node {
            acf {
              background_image {
                source_url
              }
              title
              paragraph_1
              paragraph_2
            }
          }
        }
      }
    }
  `)

  console.log(PageVisionData)

  const ACFpath = PageVisionData.allWordpressPage.edges[0].node.acf
  const BackgroundImageUrl = ACFpath.background_image.source_url
  const Title = ACFpath.title
  const Paragraph01 = ACFpath.paragraph_1
  const Paragraph02 = ACFpath.paragraph_2

  return (
    <Layout>
      <SEO title="Vision" />

      <div className={styles.vision}>

        <section className={'marginSection paddingSection ' + styles.opening}>
          <div className={'flex'}>
            <div className={'col-12 offset-1 offset-m-1'}>
              <h2>{Title}</h2>
            </div>
            <div className={'col-3 col-l-6 col-s-10 offset-3 offset-m-1'}>
              <div dangerouslySetInnerHTML={{ __html: Paragraph01 }}></div>
            </div>
          </div>
        </section>

        <div className={'flex right'}>
          <img className={styles.imageBg} src={BackgroundImageUrl}></img>
        </div>

        <section className={'marginSection paddingSection'}>
          <div className={'flex'}>
            <div className={'col-4 col-l-5 col-s-10 offset-6 offset-l-4 offset-s-1'}>
              <div dangerouslySetInnerHTML={{ __html: Paragraph02 }}></div>
            </div>
          </div>
        </section>

      </div>

    </Layout>
  )
}
export default PageVision
